<template>
  <div>
    <v-menu
      class="main-menu"
      transition="slide-y-transition"
      bottom
      offset-y
      left
      v-model="menuOpen"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-capitalize font-weight-medium"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar
            color="rgb(42, 117, 216)"
            size="30"
            class="main-menu__avatar"
            id="v-step-0"
            >{{ getAvatarLetter }}</v-avatar
          >
        </v-btn>
      </template>
      <v-list nav class="main-menu__dropdown" color="#324152">
        <v-list-group v-model="isDocumentsActive" dense @click.stop>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="main-menu__title" color="white">
                <span class="main-menu__icon">
                  <v-icon color="white" dark>mdi-file-document </v-icon>
                </span>
                {{ $t("documents") }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item to="/release-notes" link>
            <v-list-item-title class="main-menu__title">{{
              $t("releaseNote")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/documents" link>
            <v-list-item-title class="main-menu__title">{{
              $t("documents")
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group v-model="isSettingsActive" dense @click.stop>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="main-menu__title">
                <span class="main-menu__icon">
                  <v-icon color="white" dark>mdi-cog</v-icon>
                </span>
                {{ $t("settings") }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item to="/settings-general" link>
            <v-list-item-title class="main-menu__title">{{
              $t("general")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/settings-personal" link>
            <v-list-item-title class="main-menu__title">{{
              $t("personal")
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item link to="/user-payments">
          <v-list-item-title class="main-menu__title">
            <span class="main-menu__icon">
              <v-icon color="white" dark>mdi-receipt</v-icon>
            </span>
            {{ $t("receipts") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item link to="/user-referral">
          <v-list-item-title class="main-menu__title">
            <span class="main-menu__icon">
              <v-icon color="white" dark>mdi-account-plus </v-icon>
            </span>
            {{ $t("referral") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item link to="/faq">
          <v-list-item-title class="main-menu__title">
            <span class="main-menu__icon">
              <v-icon color="white" dark>mdi-magnify </v-icon>
            </span>
            {{ $t("faq0") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item link @click="openReportModal">
          <v-list-item-title class="main-menu__title">
            <span class="main-menu__icon">
              <v-icon color="white" dark>mdi-alert-circle </v-icon>
            </span>
            {{ $t("reportBugs") }}
          </v-list-item-title>
        </v-list-item>
        <hr />
        <v-list-item
          @click.prevent="downloadBot()"
          link
          class="mt-2"
          id="v-step-1"
        >
          <v-list-item-title class="main-menu__title">
            <span class="main-menu__icon">
              <v-icon color="white" dark>mdi-download</v-icon>
            </span>
            {{ $t("downloadApp") }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="$store.dispatch('logout')" link>
          <v-list-item-title class="main-menu__title">
            <span class="main-menu__icon">
              <v-icon color="white" dark>mdi-exit-to-app</v-icon>
            </span>
            {{ $t("logout") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      content-class="download-progress"
      persistent
      style="overflow: none"
      :value="downloading"
    >
      <v-progress-circular indeterminate opacity="50" size="64">
        <v-icon size="50">$vuetify.icons.gbot</v-icon>
      </v-progress-circular>
    </v-dialog>

    <v-dialog v-model="isReportActive" width="500">
      <ReportBugs @clicked="isReportActive = false" />
    </v-dialog>
    <v-dialog v-model="getStartedDialog" width="500">
      <GetStartedDialog
        @getStarded="getStarded"
        @skip="getStartedDialog = false"
      />
    </v-dialog>
    <v-tour name="myTour" sticky :steps="steps" :options="myOptions"></v-tour>
    <v-dialog v-model="isPersonalDataCorrect" width="800px">
      <UserPersonalData
        :user="userInfo"
        :showAdditionalText="true"
      ></UserPersonalData>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/api";
import ReportBugs from "../ReportBugs.vue";
import GetStartedDialog from "./GetStartedDialog.vue";
import AuthService from "../../modules/auth/services/auth.service";
import UserPersonalData from "../../modules/general/components/PersonalSettingsView/PersonalSettingsUserData.vue";

export default {
  components: { ReportBugs, GetStartedDialog, UserPersonalData },
  name: "AppBarMenu",

  data() {
    return {
      userName: "",
      isSettingsActive: false,
      isDocumentsActive: false,
      isReportActive: false,
      downloading: false,
      menuOpen: false,
      userInfo: {},
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: this.$t("buttonSkip"),
          buttonPrevious: this.$t("buttonPrevious"),
          buttonNext: this.$t("buttonNext"),
          buttonStop: this.$t("buttonStop"),
        },
      },
      steps: [
        {
          target: "#v-step-0",
          header: {
            title: this.$t("vtour2Step"),
          },
          content: this.$t("vtour2"),
          params: {
            placement: "left",
          },
        },
        {
          target: "#v-step-1", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("vtour3Step"),
          },
          content: this.$t("vtour3"),
          params: {
            placement: "left",
          },
          before: () =>
            new Promise((resolve) => {
              this.menuOpen = true;
              new Promise((r) => setTimeout(r, 2000));
              this.sleep(100).then(() => {
                resolve(true);
              });
            }),
        },
      ],
    };
  },
  async mounted() {
    this.getUserName();
    this.userInfo = await  AuthService.getUserPersonalData();
  },
  computed: {
    getStartedDialog() {
      return this.$store.getters.getShowTutorial;
    },
    getAvatarLetter() {
      return this.userName.charAt(0);
    },
    isPersonalDataCorrect() {
      return !this.$store.getters.isPersonalDataCorrect;
    },
  },
  methods: {
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    getStarded() {
      this.$store.dispatch("setShowTutorial", false);
      this.sleep(200).then(() => {
        this.$tours["myTour"].start();
      });
    },
    async getUserName() {
      try {
        const res = await api.post("/api/User/GetUserName");
        this.userName = res.data.value;
      } catch (err) {
        this.userName = "USER";
      }
    },
    openReportModal() {
      this.isReportActive = true;
    },
    downloadBot() {
      this.downloading = true;
      
      window.Moengage.track_event("download_bot");
      this.$gtm.trackEvent({event: "interaction", category: "Download bot", label: "Download bot started"});

      api
        .get("/api/TradingBot/DownloadBotV4", { responseType: "blob" })
        .then((response) => {
          this.downloading = false;
          const link = document.createElement("a");
          const downloadedFile = new Blob([response.data], {
            type: response.data.type,
          });
          link.href = URL.createObjectURL(downloadedFile);
          link.download = response.headers["filename"];
          link.click();
          URL.revokeObjectURL(link.href);
          this.$gtm.trackEvent({event: "interaction", category: "Download bot", label: "Download bot finished"});
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response != null &&
            error.response != undefined &&
            error.response.status == 400 &&
            error.response.data != null &&
            error.response.data != undefined &&
            error.response.data.error != null &&
            error.response.data.error != undefined
          ) {
            this.$store.dispatch("raiseNotification", {
              show: true,
              color: "error",
              text: error.response.data.error,
            });
          }
          this.downloading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.main-menu__dropdown {
  padding: 5px 8px;
  border-radius: 6px !important;
  hr {
    width: auto;
    margin: 3px 0;
  }
  .v-list-group__items {
    background: #ffffff13;
    margin: 6px -8px;
    padding: 0 8px;
  }
}
.main-menu__avatar {
  margin: 0 4px;
}
.main-menu__title {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: white;
}
.main-menu__icon {
  margin-right: 8px !important;
  margin-left: 0 !important;
  i {
    font-size: 20px !important;
  }
}
.download-progress {
  background: transparent !important;
  width: auto !important;
  overflow: hidden !important;
  box-shadow: none !important;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
