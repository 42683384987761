import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuex from "vuex";
import vuetify from "./plugins/vuetify";
import { i18n } from "./plugins/i18n";
import VueExcelXlsx from "vue-excel-xlsx";
import VueCookies from "vue-cookies";
import { store } from "./store";
import DatetimePicker from "vuetify-datetime-picker";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import VueTour from "vue-tour";
import VueGtm from "@gtm-support/vue2-gtm";

import setupInterceptors from "./services/setupInterceptors";

import { registerModules } from "./services/register-modules";
import authModule from "./modules/auth";
import adminModule from "./modules/admin";
import futuresModule from "./modules/futures";
import spotModule from "./modules/spot";
import generalModule from "./modules/general";

require("vue-tour/dist/vue-tour.css");
registerModules({
  auth: authModule,
  admin: adminModule,
  futures: futuresModule,
  spot: spotModule,
  general: generalModule,
});

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueTour);
Vue.use(VueCookies);
Vue.use(DatetimePicker);
Vue.use(VueExcelXlsx);
setupInterceptors(store);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

var ID = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID;
if (window.location.host.endsWith(".si")) {
  ID = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID_SI;
}

Vue.use(VueGtm, {
  id: ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  //   queryParams: {
  //     // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  //     gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //     gtm_preview: 'env-4',
  //     gtm_cookies_win: 'x',
  //   },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  //nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});
